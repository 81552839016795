<template >
  <div class="Producto">
    <HeaderMenu />
    <div class="head-nav-cont">
      <div class="nav-product">
        <div class="nav-pro-cont">
          <input type="checkbox" name="check" id="checknav" />
          <label for="checknav" class="label1">
            <div class="nav-btn">
              <p>Categoría</p>
              <img src="../assets/arrow2.svg" alt="arrow" />
            </div>
          </label>
          <div class="nav-options">
            <a href="/tienda/bovinos">Bovinos</a>
            <a href="/tienda/equinos">Equinos</a>
            <a href="/tienda/mascotas">Mascotas</a>
            <!--<a href="/tienda/">Ovinos</a>
            <a href="/tienda/">Cerdos</a>-->
            <a href="/tienda/aves">Aves</a>
            <a href="/tienda/agricola">Agrícola</a>
            <a href="/tienda/ferreteria">Ferretería</a>
            <!--<a href="/tienda/">Otros</a>-->
          </div>
          <div class="nav-free-send" v-if="validText(gp)"> 
            <img src="../assets/trailer.svg" alt="trailer" />
            <p>{{gp[0].texto}} </p>
          </div>
        </div>
      </div>
      <div class="header-prod  pc" :style="{'background-image':'url('+isWebp(require('../assets/banner1.webp'), require('../assets/banner1.png'))+')'}"></div>
      <div class="header-prod mo " :style="{'background-image':'url('+isWebp(require('../assets/banner2.webp'), require('../assets/banner2.png'))+')'}"></div>
    </div>

    <div class="tienda-cont" v-if="data !== 'No se encontraron coincidencias'">
      <div
        class="tienda-cont-el"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-duration="1000"
        :data-aos-delay="200"
      >
        <p v-if="this.$route.name == 'TiendaSearch'">
          Resultados de busqueda: <b>{{ this.$route.params.search }}</b
          >.
        </p>
        <div
          class="products-cont"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="1000"
          :data-aos-delay="400"
        >
          <div class="product-info" v-for="(shop, i) in data" :key="i">
            <a class="centeredInfo" :href="'/producto/' + shop.url">
              <!--v-if="shop.categoria == catSelected"-->
              <div class="product-img">
                <img
                  :src="
                    urlpath.url() +
                    '/P6H_3Pm/bt7-EO4/get-product-image/' +
                    isWebp(regexImg(shop.image), shop.image)
                  "
                  alt="img"
                  v-if="shop.image !== 'undefined'"
                />
                <img :src="isWebp(require('../assets/logo-agriga.webp'), require('../assets/logo-agriga.png'))" alt="img" v-else />
              </div>
              <p class="titulo-product">{{ shop.name }}</p>
              <p class="price-product">${{ shop.price }}MXN</p>
              <a :href="shop.link" class="btn-comprar">
                <img src="../assets/carrito-w.svg" alt="cart" class="cart" />
                <p>COMPRAR</p>
              </a>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="tienda-cont sin-productos" v-else>Aún no tenemos productos registrados.</div>

    <Footer />
  </div>
</template>
<script>
import HeaderMenu from "../components/fixedItems/HeaderMenu.vue";
import Footer from "../components/fixedItems/Footer.vue";
let urlpath = require("../global/url");
import { mapActions } from "vuex";
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    HeaderMenu,
    Footer,
    Carousel,
    Slide,
  },
  data() {
    return {
      selectNumb: 1,
      urlpath,
    };
  },
      metaInfo(){
        return{
            title: this.$route.name == 'TiendaSearch' ? ('Resultados: '+this.$route.params.search) : 'Tienda',
            titleTemplate: "%s | Agriga de México",
            htmlAttrs: {
            lang: "es",
            },
            meta: [
            { charset: "utf-8" },
            {
                name: "description",
                content: this.$route.name == 'TiendaSearch' ? (this.data == 'No se encontraron coincidencias' ? 'No hemos encontrado el resultado de tu búsqueda' : 'Se encontró resultados de "'+this.$route.params.search+'".') : "Disfruta de la variedad, calidad y promociones de Agriga de México. Alimento para animales, tanto ganado como mascotas. Contamos con servicio a domicilio local y nacional.",
            },
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            ],            
        };
      },
  async created() {
    await this.getAllInfoPro3("product");
    let payload1 = {
      option: "product",
      search: this.$route.params.search,
    };
    await this.searchPro2(payload1);
    await this.getAllInfoGlp('global_params')
  },
  computed: {
    elon() {
      return this.$store.getters["product/data2"];
    },
    data() {
      let array = this.$store.getters["product/data3"];
      let sarr = this.$store.getters["product/data2"];
      if(this.$route.name == 'TiendaSearch'){
        return sarr;
      }else{
      let finaldata = [];
      for (let k = 0; k < array.length; k++) {
        if (array[k].status == "ACTIVO") {
          finaldata.push(array[k]);
        }
      }
      return finaldata;
      }
    },
   
         gp() {
             
              let arr = this.$store.getters["global_params/data"]; 
                      let filter =[]
              if(!Array.isArray(arr)){
                return []
              }else{  
                  filter = arr
              }
              
                return filter


        },
  },
  methods: {
    ...mapActions("product", ["findByUrlPro"]),
    ...mapActions("product", ["getAllInfoPro3"]),
    ...mapActions("product", ["searchPro2"]),
       ...mapActions('global_params', ['getAllInfoGlp']),

      validText(gp){
       
        if(!gp) return false;
        if(!Array.isArray(gp)) return false;
        
        if(gp.length==0) return false;
        if(!gp[0]?.texto ) return false;
        
      
        if(gp[0]?.texto != ''){
          return true;
        }else {
          return false;
        }
    },

    regexImg(txt) {
      if(txt){
        let s = txt.split(".");
      return s[0] + ".webp";
      }else{
        return ''
      }
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    }, 

  },
 /* watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Tienda - Agriga de México";
      },
    },
  },*/
};
</script>
<style >
a,
p {
  font-family: "Helvetica World Regular";
}
.nav-product {
  background: #fe9128;
  display: flex;
  justify-content: center;
  align-items: center;
}
input#checknav {
  display: none;
}
.nav-pro-cont {
  width: 100%;
  padding: 0.729vw 3.49vw;
  display: flex;
  justify-content: space-between;
}
.nav-free-send {
  display: flex;
  align-items: center;
}
.nav-free-send img {
  width: auto;
  height: 1.563vw;
  margin-right: 1.771vw;
}
.nav-free-send p {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 99.5%;
  color: #ffffff;
  margin: 0;
}
.nav-options {
  display: flex;
  align-items: center;
  width: 37.76vw;
  justify-content: space-between;
}
.nav-options a {
  font-weight: 700;
  font-size: 0.781vw;
  line-height: 194.5%;
  color: #ffffff;
  text-decoration-color: transparent;
  transition: 0.5s;
}
.nav-options a:hover {
  text-decoration-color: #ffffff;
}
.header-prod {
  width: 100%;
  height: 5.365vw;
  /*background-image: url("../assets/banner1.png");*/
  background-size: 100%;
  background-repeat: no-repeat;
}
.cant-select {
  display: flex;
  width: 3.49vw;
  height: 2.396vw;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid gainsboro;
}
.number-selects {
  width: 3.49vw;
  height: 0;
  pointer-events: none;
  overflow: hidden;
  border: 1px solid gainsboro;
  position: absolute;
  background: white;
  transition: 0.5s;
}
#checkoptions:checked ~ .number-selects {
  height: 10vw;
  pointer-events: all;
}
.number-s-cont {
  overflow-y: scroll;
  height: 10vw;
}
.producto-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75.677vw;
  height: auto;
  margin: auto;
  padding: 5.052vw 0 12.708vw;
}
.number-s-cont p {
  margin: 0;
  cursor: pointer;
  width: 100%;
  height: 2vw;
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 129.5%;
  color: #929292;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: 0.5s;
}
.number-s-cont p:hover {
  background-color: rgb(227, 225, 225);
}
.cantidad-cont {
  display: flex;
  position: relative;
  z-index: 9;
}
.cantidad-op-cont input {
  display: none;
}
.prod-image {
  width: 30.833vw;
  height: 36.094vw;
  border: 1.5px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prod-image img {
  width: 17.396vw;
  height: auto;
}
.prod-image.pc {
  display: flex;
}
.prod-image.mo {
  display: none;
}
.info-cont {
  width: 40.365vw;
  height: auto;
}
p.titulo-info {
  margin: 0;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 2.083vw;
  line-height: 2.5vw;
  color: #000702;
}
p.brand-info {
  padding: 0.781vw 0;
  margin: 0;
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 129.5%;
  color: #929292;
}
p.price-info {
  margin: 0;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.823vw;
  line-height: 129.5%;
  color: #3ebdb1;
}
p.descrp-info {
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 194.5%;
  color: #929292;
  margin: 2.344vw 0;
}
.cantidad-cont .cnt {
  font-weight: 700;
  font-size: 0.781vw;
  line-height: 129.5%;
  text-align: center;
  color: #929292;
  padding-right: 0.885vw;
}
.cant-select p {
  font-size: 0.781vw;
  color: #929292;
}
.cant-select img {
  width: 0.469vw;
  height: 0.469vw;
}
.two-btn-cont {
  padding-top: 3.073vw;
  display: flex;
  justify-content: space-between;
  width: 20.781vw;
}
.add-cart,
.shop-cart {
  width: 9.948vw;
  height: 2.552vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Intro Rust";
  font-weight: 400;
  font-size: 0.521vw;
  line-height: 129.5%;
  transition: 0.5s;
  cursor: pointer;
}
.add-cart {
  color: #fe9128;
  border: 1px solid #fe9128;
  background: white;
}
.add-cart:hover {
  color: white;
  background: #fe9128;
}
.shop-cart {
  color: #fe9128;
  background: white;
  border: 1px solid #fe9128;
}
.shop-cart:hover {
  color: white;
  background: #fe9128;
}
.shop-cart img {
  width: 0.781vw;
  height: 0.781vw;
  padding-right: 0.481vw;
}
.label1 {
  display: none;
}

/****/
a.btn-comprar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.813vw;
  height: 2.552vw;
  background: #3ebdb1;
  transition: 0.5s;
  text-decoration-color: transparent;
}
a.btn-comprar:hover {
  background: #2d8e84;
}
a.btn-comprar p {
  color: white;
  font-family: "Intro Rust";
  font-size: 0.521vw;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
img.cart {
  width: 0.781vw;
  height: auto;
  padding-right: 0.4vw;
}
p.cnd {
  margin: 4vw 8vw;
  width: auto;
  font-size: 1vw;
  color: gray;
  text-transform: uppercase;
}
.product-img {
  width: 17.604vw;
  height: 23.542vw;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-info {
  /*width: auto;
    height: auto;*/
  display: flex;
  flex-direction: column;
  align-items: center;
}
.centeredInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration-color: transparent;
  padding-right: 1.927vw;
  padding-bottom: 1.927vw;
}
.sin-productos{
    font-family: "Intro Rust";
    font-style: normal;
    font-weight: 400;
    font-size: 2.083vw;
    line-height: 1.963vw;
    color: var(--color-2);
    margin: 0;
    padding-top:5.313vw ;
    padding-bottom: 5.313vw;
    text-align: center;
}
p.titulo-product {
  margin: 0 0 0.481vw;
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 129.5%;
  text-align: center;
  color: #929292;
  padding-top: 1.563vw;
  transition: 0.5s;
  text-transform: uppercase;
  width: 18.5vw;
}
.product-info:hover p.titulo-product {
  color: #4f4f4f;
}
p.price-product {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 129.5%;
  text-align: center;
  color: #fe9128;
  margin: 0;
  padding-bottom: 1.563vw;
}
.product-img img {
  width: 10.938vw;
  height: auto;
  transition: 0.5s;
}
.product-info:hover .product-img img {
  transform: scale(1.09);
}
.category-header {
  display: flex;
  padding-bottom: 3.125vw;
}
.products-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tienda-cont-el {
  padding: 5.052vw 0;
  margin: 0 5.198vw 0 11.198vw;
}
.category-header p {
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 194.5%;
  color: #bebebe;
  margin: 0;
  padding-right: 1.8vw;
  cursor: pointer;
  transition: 0.5s;
}
.category-header p:hover {
  color: #575757;
}
p.realed {
  font-weight: 700;
  text-decoration-line: underline;
  color: #3ebdb1;
}
.category-header p.realed:hover {
  color: #25756d;
}
@media (min-width: 768px) {
  .header-prod.mo{
    display: none;
  }
  .header-prod.pc{
    display: block;
  }
}
@media (max-width: 768px) {







  .label1 {
    display: block;
  }
  .head-nav-cont {
    display: flex;
    flex-direction: column-reverse;
  }
  .header-prod.mo{
    display: block;
  }
  .header-prod.pc{
    display: none;
  }
  .header-prod {
    height: 22.879vw; 
  }
  .producto-cont {
    flex-direction: column-reverse;
    padding: 11.594vw 0 27.778vw;
  }
  .prod-image.pc {
    display: none;
  }
  .prod-image.mo {
    display: flex;
  }
  p.titulo-info {
    font-size: 5.314vw;
    line-height: 6.28vw;
  }
  .info-cont {
    width: 100%;
  }
  p.brand-info {
    padding: 3.382vw 0;
    font-size: 3.14vw;
  }
  p.price-info {
    font-size: 6.28vw;
  }
  .prod-image {
    margin-top: 13.285vw;
    width: 100%;
    height: 93.478vw;
  }
  .prod-image img {
    width: 43.396vw;
    height: auto;
  }
  p.descrp-info {
    font-size: 3.382vw;
    margin: 12.802vw 0;
  }
  .cantidad-cont .cnt {
    font-size: 3.623vw;
    padding-right: 1.885vw;
  }
  .cant-select {
    width: 16.184vw;
    height: 11.111vw;
  }
  #checkoptions:checked ~ .number-selects {
    height: 39vw;
    pointer-events: all;
  }
  .number-s-cont {
    height: 39vw;
  }
  .number-selects {
    width: 16.184vw;
  }
  .cant-select p {
    font-size: 2.781vw;
  }
  .number-s-cont p {
    height: 6vw;
    font-size: 2.781vw;
  }
  .cant-select img {
    width: 1.469vw;
    height: 1.469vw;
  }
  .two-btn-cont {
    padding-top: 17.633vw;
    width: 100%;
    flex-direction: column;
  }
  .add-cart,
  .shop-cart {
    width: 100%;
    height: 16.425vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Intro Rust";
    font-weight: 400;
    font-size: 2.415vw;
    line-height: 129.5%;
    transition: 0.5s;
    cursor: pointer;
  }
  .shop-cart img {
    width: 3.781vw;
    height: 3.781vw;
    padding-right: 3.481vw;
  }
  .add-cart {
    margin-bottom: 1.932vw;
  }
  .nav-btn {
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    transition: 0.5s;
  }
  .nav-btn:hover {
    border-bottom: 1px solid white;
  }
  .nav-btn p {
    font-weight: 700;
    font-size: 2.899vw;
    line-height: 194.5%;
    color: #ffffff;
    margin: 0;
    padding-right: 0.8vw;
  }
  .nav-btn img {
    width: 1.932vw;
    height: 1.932vw;
  }
  .nav-pro-cont {
    padding: 2.899vw 7.246vw;
  }
  .nav-options {
    width: 100%;
    z-index: 9;
    position: absolute;
    flex-direction: column;
    background: #fe9128;
    margin-top: 8vw;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  #checknav:checked ~ .nav-options {
    opacity: 1;
    pointer-events: all;
  }
  .nav-options a {
    font-weight: 700;
    font-size: 2.781vw;
    line-height: 214.5%;
  }
  .nav-free-send img {
    width: auto;
    height: 4.831vw;
    margin-right: 2.171vw;
  }
  .nav-free-send p {
    font-size: 2.899vw;
  }
  .nav-free-send {
    width: 46vw;
  }

  /** */

  p.cnd {
    margin: 18vw 8vw;
    font-size: 3vw;
  }
  .header-tienda {
    height: 50.483vw;
    background-size: 275vw;
    background-position-x: center;
    font-size: 8.454vw;
  }
  .category-header {
    padding-bottom: 7.493vw;
    margin-bottom: 7.493vw;
    overflow-x: scroll;
  }
  .product-img {
    width: 38.647vw;
    height: 51.691vw;
  }
  .product-img img {
    width: 23.913vw;
  }
  p.titulo-product {
    font-size: 2.415vw;
    padding-top: 4.589vw;
    width: 37.5vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  p.price-product {
    font-size: 3.14vw;
    padding-bottom: 4.589vw;
  }
  a.btn-comprar {
    width: 38.647vw;
    height: 10.386vw;
  }
  a.btn-comprar p {
    font-size: 2.174vw;
  }
  img.cart {
    width: 2.781vw;
    padding-right: 1.4vw;
  }
  .centeredInfo {
    padding-right: 4.348vw;
    padding-bottom: 4.348vw;
  }
  .tienda-cont-el {
    padding: 12.077vw 0;
    margin: 0 3.198vw 0 6.198vw;
  }
  .category-header p {
    font-size: 3.865vw;
    line-height: 194.5%;
    padding-right: 6.8vw;
  }
}
</style>